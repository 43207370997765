'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box, Slider } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'
import Icon32Playlist from 'src/assets/icons/Icon32Playlist'
import useIsScrollDown from '@page/article/_components/ArticleHeader/StickyHeader/hooks/useIsScrollDown'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import AppAodControls from './AppAodControls'
import AppAodPlayer from './drawer/AppAodPlayer'
import { useSetAppAodStates } from './store/store'
import AppAodPlaylist from './drawer/AppAodPlaylist'
import { createPrefetchAll } from '@utils/createPrefetchQuery'
import { useProgramAodMutation } from '@services/api/Program/Aod'
import { useProgramAodListMutation } from '@services/api/Program/AodList'
import {
	ApiResultVoListProgramAodList,
	ApiResultVoProgramAodResponseVo,
	ProgramAodList,
	ProgramAodResponseVo,
} from '@schemas/non-auth'
import { DATE_FORMAT, dayjs } from '@utils/date'
import { appInterface, useIsAppControl } from '@store/isApp'
import { useRecoilValue } from 'recoil'
import { useAodControl } from '@store/aod'
import { useAodConfigControl } from '@store/aodConfig'
import { useStatisticsAodReadMutation } from '@services/api/Statistics/AodRead/mutation'

interface SendAppAodInfoProps {
	totalPlayTime: number
}
interface SendAppAodPauseProps {
	isPause: boolean
}
interface SendAppAodSeekToProps {
	playTime: string
}
interface AodReq {
	programIdx: string
	date?: string
	audioIdx?: string
}
interface AodBarRootProps {
	data: AodReq
	onClose: () => void
}
const AppAodBarRoot = ({ data, onClose }: AodBarRootProps) => {
	const nativeEvent = useRecoilValue(appInterface)
	const searchParams = useSearchParams()
	const { ui } = Object.fromEntries(searchParams)
	const { aod, setPlayAod, setNewsReadClicked, newsReadClicked } = useAodControl()
	const { aodConfig, setAodConfig } = useAodConfigControl()
	const { appInfo } = useIsAppControl()
	const pathname = usePathname()
	const isScrollDown = useIsScrollDown()
	const setAppAodStates = useSetAppAodStates()
	const [isRead, setIsRead] = useState<boolean>(false)
	const [aodInfo, setAodInfo] = useState<ProgramAodResponseVo>()
	const [aodList, setAodList] = useState<Array<ProgramAodList>>()
	const [currentAod, setCurrentAod] = useState<ProgramAodList>()
	const [playIndex, setPlayIndex] = useState<number>(0)
	const [currentTime, setCurrentTime] = useState<number>(0)
	const [duration, setDuration] = useState<number>(0)
	const [paused, setPaused] = useState<boolean>(true)
	const [activeUI, setActiveUI] = useState<string>()
	const audio = useRef<HTMLAudioElement>(new Audio()).current
	const currentDate = dayjs().format(DATE_FORMAT.DATE_AS_NUMBER)
	const isArticle = pathname.includes('/article')

	const { mutateAsync: mutateAodRead } = useStatisticsAodReadMutation()
	const { mutateAsync: mutateProgramAod } = useProgramAodMutation()
	const { mutateAsync: mutateProgramAodList } = useProgramAodListMutation()

	const historyReplace = useCallback(() => {
		/**
		 * @description
		 * ui=player param 삭제 후, history 저장
		 * 새로고침 issue
		 */
		window?.history?.replaceState(null, '', pathname)
	}, [pathname])
	const handleAodPlayerOpen = () => {
		setAppAodStates((prev) => ({
			...prev,
			playerOpen: true,
		}))
	}
	const handleAodPlaylistOpen = () => {
		setAppAodStates((prev) => ({
			...prev,
			playlistOpen: true,
		}))
	}
	const setPlayRateForAndroid = useCallback(() => {
		nativeEvent({
			key: 'setAodPlayRate',
			value: {
				playRate: aodConfig.playbackRate?.toFixed(1),
			},
		})
	}, [aodConfig.playbackRate, nativeEvent])

	/** Audio 정보 설정 Evnet */
	const setAudio = useCallback(
		(item: ProgramAodList) => {
			if (item) {
				setIsRead(false)
				setPlayAod(item.audioIdx)
				const title = dayjs(item?.insertDate).format(DATE_FORMAT.DATE_FULL_WITH_WEEKDAY)
				if (appInfo?.DType === 'A') {
					nativeEvent({
						key: 'setAodSetPlay',
						value: {
							title,
							playUrl: item.audioUrl,
							image: aodInfo?.programThumbnailImgUrl ?? '',
							programName: aodInfo?.programName ?? '',
							duration: '',
							isLive: false,
						},
					})
					nativeEvent({ key: 'setAodSeekTo', value: { playTime: 0 } })
					setPlayRateForAndroid()
					if (activeUI) {
						setActiveUI(undefined)
						historyReplace()
					}
				} else {
					audio.src = item.audioUrl
					audio.currentTime = 0
					try {
						if (navigator?.mediaSession) {
							navigator.mediaSession.metadata = new MediaMetadata({
								title,
								artist: aodInfo?.programName ?? '',
								artwork: [{ src: aodInfo?.programThumbnailImgUrl ?? '' }],
							})
						}
					} catch (error) {
						console.error(error)
					}
					setTimeout(() => {
						audio.load()
						audio.playbackRate = aodConfig.playbackRate ?? 1
					})
				}
			}
		},
		[
			nativeEvent,
			appInfo,
			aodInfo,
			activeUI,
			aodConfig,
			historyReplace,
			newsReadClicked,
			setPlayRateForAndroid,
		],
	)

	useEffect(() => {
		try {
			if (navigator?.mediaSession) {
				navigator.mediaSession.setActionHandler('play', () => {
					audio.play()
					setPaused(false)
				})

				navigator.mediaSession.setActionHandler('pause', () => {
					audio.pause()
					setPaused(true)
				})
			}
		} catch (error) {
			console.error(error)
		}
	}, [aodInfo, audio])

	/** Audio 종료 Evnet */
	const destroyAudio = useCallback(() => {
		if (appInfo?.DType === 'A') {
			if (aod.programIdx) {
				// AOS aod null 분기
				nativeEvent({ key: 'exitAod', value: null })
				setCurrentTime(0)
			}
		} else {
			audio.pause()
			audio.src = ''
			audio.currentTime = 0
			setPaused(true)
		}
	}, [appInfo?.DType, aod.programIdx, nativeEvent, audio])

	/** Audio 재생 여부 갱신 Evnet */
	const handlePlay = useCallback(() => {
		if (appInfo?.DType === 'A') {
			nativeEvent({
				key: 'setAodPause',
				value: {
					isPause: !paused,
				},
			})
		} else if (paused) {
			audio.play()
			setPaused(false)
		} else {
			audio.pause()
			setPaused(true)
		}
	}, [appInfo, paused, nativeEvent, audio])

	/** Audio 현재 재생 시간 갱신 Evnet */
	const updateCurrentTime = useCallback(
		(value: number) => {
			const updateTime = value < 0 ? 0 : value > duration ? duration : value
			if (appInfo?.DType === 'A') {
				nativeEvent({ key: 'setAodSeekTo', value: { playTime: updateTime.toString() } })
				setCurrentTime(updateTime)
			} else {
				audio.currentTime = updateTime
				setCurrentTime(updateTime)

				// navigator?.mediaSession.setPositionState({
				// 	position: updateTime,
				// })
			}
		},
		[nativeEvent, appInfo, duration],
	)
	/** Audio 재생 여부 통계용 Evnet */
	const updateAodReadCount = useCallback(() => {
		if (aod.playAodIdx && !isRead) {
			setIsRead(true)
			mutateAodRead({ audioIdx: aod.playAodIdx as string })
		}
	}, [aod.playAodIdx, isRead])

	/** Android APP - Audio 정보 수신 */
	const sendAppAodInfo = ((event: CustomEvent<SendAppAodInfoProps>) => {
		setDuration(Number(event.detail.totalPlayTime))
	}) as EventListener
	/** Android APP - Audio 재생 여부 수신  */
	const sendAppAodPause = ((event: CustomEvent<SendAppAodPauseProps>) => {
		setPaused(event.detail.isPause)
	}) as EventListener
	/** Android APP - Audio 현재 재생 시간 수신  */
	const sendAppAodSeekTo = ((event: CustomEvent<SendAppAodSeekToProps>) => {
		setCurrentTime(Number(event.detail.playTime))
	}) as EventListener
	/** Android APP - Audio 종료시 수신  */
	const sendAppAodStop = ((event: CustomEvent) => {
		onClose()
	}) as EventListener

	const getAodInfo = async ({ programIdx, audioIdx, date }: AodReq) => {
		const response: {
			info: ApiResultVoProgramAodResponseVo
			list: ApiResultVoListProgramAodList
		} = await createPrefetchAll({
			info: mutateProgramAod({ programIdx }),
			list: mutateProgramAodList({
				programIdx,
				searchDate: date,
			}),
		})
		setAodInfo(response.info.data)
		if (response.list.data) {
			const list = response.list.data as Array<ProgramAodList>
			const currentDateAod = list.findIndex(
				(item) => dayjs(item.insertDate).format(DATE_FORMAT.DATE_AS_NUMBER) === date,
			)
			const currentAodIdx = list.findIndex((item) => item.audioIdx === audioIdx)

			setAodList(list)
			if (currentAodIdx >= 0) {
				setPlayIndex(currentAodIdx)
			} else {
				setPlayIndex(currentDateAod < 0 ? 0 : currentDateAod)
			}
		}
	}

	useEffect(() => {
		if (currentAod) {
			setAudio(currentAod)
		}
	}, [currentAod, newsReadClicked])

	useEffect(() => {
		return () => {
			destroyAudio()
		}
	}, [])

	useEffect(() => {
		getAodInfo(data)
	}, [data.programIdx, data.date, data.audioIdx])

	useEffect(() => {
		if (aodList) {
			const playAod = aodList?.[playIndex]
			setCurrentAod(playAod)
		}
	}, [aodList, playIndex])

	useEffect(() => {
		const loadeddata = () => {
			if (!activeUI) {
				audio.play()
				setPaused(false)
			} else {
				setActiveUI(undefined)
				historyReplace()
			}
			setDuration(audio.duration)
		}
		const timeUpdate = () => {
			setCurrentTime(audio.currentTime)
		}
		if (appInfo?.DType === 'A') {
			window.addEventListener('sendAppAodInfo', sendAppAodInfo)
			window.addEventListener('sendAppAodPause', sendAppAodPause)
			window.addEventListener('sendAppAodSeekTo', sendAppAodSeekTo)
			window.addEventListener('sendAppAodStop', sendAppAodStop)
		} else {
			audio.addEventListener('loadeddata', loadeddata)
			audio.addEventListener('timeupdate', timeUpdate)
		}
		return () => {
			if (appInfo?.DType === 'A') {
				window.removeEventListener('sendAppAodInfo', sendAppAodInfo)
				window.removeEventListener('sendAppAodPause', sendAppAodPause)
				window.removeEventListener('sendAppAodSeekTo', sendAppAodSeekTo)
				window.removeEventListener('sendAppAodStop', sendAppAodStop)
			} else {
				audio.removeEventListener('loadeddata', loadeddata)
				audio.removeEventListener('timeupdate', timeUpdate)
			}
		}
	}, [appInfo, activeUI, historyReplace, setPlayRateForAndroid, sendAppAodPause])

	useEffect(() => {
		if (!audio) return
		if (aodConfig) {
			if (appInfo?.DType === 'I') {
				audio.playbackRate = aodConfig.playbackRate ?? 1
				audio.volume = aodConfig.mute ? 0 : (aodConfig.volume as number)
			} else if (appInfo?.DType === 'A') {
				setPlayRateForAndroid()
			}
		}
	}, [audio, aodConfig, appInfo, setPlayRateForAndroid])

	useEffect(() => {
		setActiveUI(ui)

		if (appInfo?.DType === 'A') {
			nativeEvent({ key: 'requestWebviewHistoryClear', value: null })
		}
	}, [ui, appInfo?.DType, nativeEvent])
	useEffect(() => {
		setTimeout(() => {
			if (activeUI === 'player') {
				handleAodPlayerOpen()
			}
			if (activeUI === 'playlist') {
				handleAodPlaylistOpen()
			}
		}, 200)
	}, [activeUI])
	useEffect(() => {
		if (!isRead) {
			if (currentTime > 10) {
				updateAodReadCount()
			}
		}
	}, [updateAodReadCount, currentTime, isRead])

	useEffect(() => {
		const handlePlayEvent = () => {
			setPaused(false)
			// handle additional logic when audio starts playing
		}

		const handlePauseEvent = () => {
			setPaused(true)
			// handle additional logic when audio is paused
		}

		if (audio) {
			audio.addEventListener('play', handlePlayEvent)
			audio.addEventListener('pause', handlePauseEvent)
		}

		return () => {
			if (audio) {
				audio.removeEventListener('play', handlePlayEvent)
				audio.removeEventListener('pause', handlePauseEvent)
			}
		}
	}, [audio])
	useEffect(() => {
		return () => {
			setAppAodStates({
				playerOpen: false,
				playlistOpen: false,
			})
		}
	}, [setAppAodStates])

	useEffect(() => {
		if (newsReadClicked) {
			setNewsReadClicked(false)
			setCurrentAod(aodList?.[playIndex])
			setAodList(aodList)
		}
	}, [newsReadClicked, aodList, playIndex, setNewsReadClicked, aod])

	return (
		currentAod && (
			<Box
				position="sticky"
				bottom={pxToRem(63.5)}
				left={0}
				right={0}
				zIndex={zIndexes.modal}
				bgcolor={color.colWhite}
				borderTop={`1px solid ${color.colGray3}`}
				boxShadow={0}
				sx={{
					opacity: isArticle && isScrollDown ? 0.7 : 1,
				}}
			>
				<Slider
					step={0.001}
					min={0}
					max={duration}
					value={currentTime}
					onChangeCommitted={(e, value) => updateCurrentTime(value as number)}
					sx={{
						display: 'block',
						position: 'relative',
						width: '100%',
						padding: 0,
						transform: 'none',
						height: pxToRem(22),
						marginTop: pxToRem(-10),
						marginBottom: pxToRem(-10),
						backgroundColor: 'rgba(0,0,0,0)',
						zIndex: 2,
						'.MuiSlider': {
							'&-rail': {
								background: color.colGray3,
								height: pxToRem(2),
							},
							'&-track': {
								height: pxToRem(2),
								background: color.colMintBlue,
							},
							'&-thumb': {
								display: 'none',
							},
						},
					}}
				/>
				<Box padding={`${pxToRem(14)} ${pxToRem(12)} ${pxToRem(15)}`}>
					<UIFlex
						flexDirection="row"
						alignItems="center"
						sx={{
							'&>*:not(:first-child)': {
								marginLeft: pxToRem(24),
							},
						}}
					>
						<UIFlex
							flexDirection="row"
							alignItems="center"
							flexGrow={1}
							minWidth={0}
							sx={{
								'&>*:not(:first-child)': {
									marginLeft: pxToRem(12),
								},
							}}
						>
							<button type="button" onClick={handleAodPlaylistOpen}>
								<Icon32Playlist />
								<UIText readonly>재생목록</UIText>
							</button>
							{aodInfo && (
								<AppAodPlaylist
									programIdx={aodInfo?.programIdx}
									programName={aodInfo?.programName}
									aodList={aodList}
									playIndex={playIndex}
									playAodIdx={aod.playAodIdx}
									paused={paused}
									handlePlay={handlePlay}
									setCurrentAod={setCurrentAod}
								/>
							)}
							<AppAodPlayer
								info={aodInfo as ProgramAodResponseVo}
								playAod={currentAod}
								paused={paused}
								currentTime={currentTime}
								duration={duration}
								updateCurrentTime={updateCurrentTime}
								handlePlay={handlePlay}
							/>
							{/* 프로그램 정보 */}
							<Box onClick={handleAodPlayerOpen} sx={{ userSelect: 'none' }}>
								<UIText
									fontSize={pxToRem(15)}
									lineHeight={pxToRem(22)}
									fontWeight={600}
									color={color.colBlack}
									whiteSpace="nowrap"
								>
									{currentAod?.insertDate &&
										dayjs(currentAod?.insertDate).format(
											DATE_FORMAT.DATE_FULL_WITH_WEEKDAY,
										)}
								</UIText>
								<UIText
									fontSize={pxToRem(14)}
									lineHeight={pxToRem(20)}
									fontWeight={500}
									color={color.colGray5}
									whiteSpace="nowrap"
								>
									{aodInfo?.programName ?? ''}
								</UIText>
							</Box>
							{/* Controls */}
						</UIFlex>
						<AppAodControls
							paused={paused}
							currentTime={currentTime}
							updateCurrentTime={updateCurrentTime}
							handlePlay={handlePlay}
							onClose={onClose}
						/>
					</UIFlex>
				</Box>
			</Box>
		)
	)
}

export default AppAodBarRoot
