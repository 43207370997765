'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box, Slider } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'
import { Icon32Close } from 'src/assets/icons/Icon32Close'
import { useProgramAodMutation } from '@services/api/Program/Aod'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
	ApiResultVoListProgramAodList,
	ApiResultVoProgramAodResponseVo,
	ProgramAodList,
	ProgramAodResponseVo,
} from '@schemas/non-auth'
import { createPrefetchAll } from '@utils/createPrefetchQuery'
import { useProgramAodListMutation } from '@services/api/Program/AodList'
import { DATE_FORMAT, dayjs } from '@utils/date'
import AodViewMoreModal from './AodViewMoreModal'
import AodControls from './AodControls'
import { useAodConfigControl } from '@store/aodConfig'
import { useAodControl } from '@store/aod'
import { useStatisticsAodReadMutation } from '@services/api/Statistics/AodRead/mutation'
import UIImage from '@components/ui/UIImage'

export interface AodReq {
	programIdx: string
	date?: string
	audioIdx?: string
}
interface AodBarRootProps {
	data: AodReq
	onClose: () => void
}
const AodBarRoot = ({ data, onClose }: AodBarRootProps) => {
	const { aod, setPlayAod, setNewsReadClicked, newsReadClicked } = useAodControl()
	const { aodConfig } = useAodConfigControl()
	const [isRead, setIsRead] = useState<boolean>(false)
	const [aodInfo, setAodInfo] = useState<ProgramAodResponseVo>()
	const [aodList, setAodList] = useState<Array<ProgramAodList>>()
	const [currentAod, setCurrentAod] = useState<ProgramAodList>()
	const [initPlayIndex, setInitPlayIndex] = useState<number>(0)
	const [playIndex, setPlayIndex] = useState<number>(0)
	const [currentTime, setCurrentTime] = useState<number>(0)
	const [duration, setDuration] = useState<number>(0)
	const audio = useRef<HTMLAudioElement>(new Audio()).current
	const currentDate = dayjs().format(DATE_FORMAT.DATE_AS_NUMBER)

	const { mutateAsync: mutateAodRead } = useStatisticsAodReadMutation()
	const { mutateAsync: mutateProgramAod } = useProgramAodMutation()
	const { mutateAsync: mutateProgramAodList } = useProgramAodListMutation()

	const setAudio = (item: ProgramAodList) => {
		if (item) {
			setPlayAod(item.audioIdx)
			audio.src = item.audioUrl
			audio.currentTime = 0

			const title = dayjs(item?.insertDate).format(DATE_FORMAT.DATE_FULL_WITH_WEEKDAY)
			try {
				if (navigator?.mediaSession) {
					navigator.mediaSession.metadata = new MediaMetadata({
						title,
						artist: aodInfo?.programName ?? '',
						artwork: [{ src: aodInfo?.programThumbnailImgUrl ?? '' }],
					})
				}
			} catch (error) {
				console.error(error)
			}

			setTimeout(() => {
				audio.load()
				audio.playbackRate = aodConfig.playbackRate ?? 1
				audio.volume = aodConfig.mute ? 0 : (aodConfig.volume as number)
			})
		}
	}
	/** Audio 종료 Evnet */
	const destroyAudio = () => {
		audio.pause()
		audio.src = ''
		audio.currentTime = 0
	}
	/** Audio 현재 재생 시간 갱신 Evnet */
	const updateCurrentTime = (value: number) => {
		audio.currentTime = value
		setCurrentTime(value)
	}
	/** Audio 재생 여부 통계용 Evnet */
	const updateAodReadCount = useCallback(() => {
		if (aod.playAodIdx && !isRead) {
			setIsRead(true)
			mutateAodRead({ audioIdx: aod.playAodIdx as string })
		}
	}, [aod.playAodIdx, isRead])

	const getAodInfo = async ({ programIdx, audioIdx, date }: AodReq, isNext?: boolean) => {
		const response: {
			info: ApiResultVoProgramAodResponseVo
			list: ApiResultVoListProgramAodList
		} = await createPrefetchAll({
			info: mutateProgramAod({ programIdx }),
			list: mutateProgramAodList({
				programIdx,
				searchDate: date,
			}),
		})
		setAodInfo(response.info.data)
		if (response.list.data) {
			const list = response.list.data as Array<ProgramAodList>
			const currentDateAod = list.findIndex(
				(item) => dayjs(item.insertDate).format(DATE_FORMAT.DATE_AS_NUMBER) === date,
			)
			const currentAodIdx = list.findIndex((item) => item.audioIdx === audioIdx)

			setAodList(list)
			if (currentAodIdx >= 0) {
				setPlayIndex(currentAodIdx)
				setInitPlayIndex(currentAodIdx)
			} else {
				setPlayIndex(currentDateAod < 0 ? 0 : currentDateAod)
				setInitPlayIndex(currentDateAod < 0 ? 0 : currentDateAod)
			}
		}
	}
	const handlePrev = useCallback(() => {
		setNewsReadClicked(false)
		if (aodList && aodList?.length - 1 === playIndex) {
			if (currentAod?.preAodinsertDate) {
				getAodInfo({
					programIdx: data.programIdx,
					date: dayjs(currentAod?.preAodinsertDate).format(DATE_FORMAT.DATE_AS_NUMBER),
				})
			}
		} else {
			setPlayIndex(playIndex + 1)
		}
	}, [data.programIdx, aodList, playIndex, currentAod])
	const handleNext = useCallback(() => {
		setNewsReadClicked(false)
		if (playIndex === 0) {
			if (currentAod?.nextAodinsertDate) {
				getAodInfo(
					{
						programIdx: data.programIdx,
						date: dayjs(currentAod?.nextAodinsertDate).format(
							DATE_FORMAT.DATE_AS_NUMBER,
						),
					},
					true,
				)
			}
		} else {
			setPlayIndex(playIndex - 1)
		}
	}, [data.programIdx, aodList, playIndex, currentAod])

	useEffect(() => {
		if (currentAod) {
			setAudio(currentAod)
		}
		return () => {
			if (currentAod) {
				destroyAudio()
			}
		}
	}, [currentAod])

	useEffect(() => {
		getAodInfo(data)
	}, [data.programIdx, data.date, data.audioIdx])

	useEffect(() => {
		if (aodList) {
			const playAod = aodList?.[playIndex]
			setCurrentAod(playAod)
		}
	}, [aodList, playIndex])

	useEffect(() => {
		const loadeddata = () => {
			audio.play()
			setDuration(audio.duration)
		}
		const timeUpdate = () => {
			setCurrentTime(audio.currentTime)
		}
		audio.addEventListener('loadeddata', loadeddata)
		audio.addEventListener('timeupdate', timeUpdate)

		return () => {
			audio.removeEventListener('loadeddata', loadeddata)
			audio.removeEventListener('timeupdate', timeUpdate)
		}
	}, [])

	useEffect(() => {
		if (aodConfig) {
			if (audio) {
				audio.playbackRate = aodConfig.playbackRate ?? 1
				audio.volume = aodConfig.mute ? 0 : (aodConfig.volume as number)
			}
		}
	}, [audio, aodConfig])
	useEffect(() => {
		if (!isRead) {
			if (currentTime > 10) {
				updateAodReadCount()
			}
		}
	}, [updateAodReadCount, currentTime, isRead])

	useEffect(() => {
		if (newsReadClicked) {
			setNewsReadClicked(false)
			setCurrentAod(aodList?.[initPlayIndex])
			setPlayIndex(initPlayIndex)
			audio.currentTime = 0
			audio.play()
		}
	}, [newsReadClicked, initPlayIndex, aodList, audio, playIndex, setNewsReadClicked])

	return (
		currentAod && (
			<Box
				position="sticky"
				bottom={0}
				left={0}
				right={0}
				zIndex={zIndexes.modal}
				bgcolor={color.colGray1}
			>
				{/* 재생 프로그레스바 */}
				<Slider
					step={0.001}
					min={0}
					max={duration}
					value={currentTime}
					onChangeCommitted={(e, value) => updateCurrentTime(value as number)}
					sx={{
						display: 'block',
						position: 'relative',
						width: '100%',
						padding: 0,
						transform: 'none',
						height: pxToRem(26),
						top: pxToRem(-10),
						marginBottom: pxToRem(-20),
						backgroundColor: 'rgba(0,0,0,0)',
						zIndex: 2,
						'.MuiSlider': {
							'&-rail': {
								background: color.colGray3,
								height: pxToRem(6),
								borderRadius: 0,
							},
							'&-track': {
								height: pxToRem(6),
								background: color.colMintBlue,
								borderRadius: 0,
							},
							'&-thumb': {
								display: 'none',
							},
						},
					}}
				/>
				<UIFlex
					flexDirection="row"
					justifyContent={{ xs: 'flex-end', md: 'space-between' }}
					alignItems="center"
					padding={{
						xs: `0 ${pxToRem(14)} 0 ${pxToRem(20)}`,
						md: `0 ${pxToRem(20)}`,
					}}
					height={pxToRem(72)}
					position="relative"
					sx={{
						'&>*:not(:first-child)': {
							marginLeft: {
								xs: pxToRem(4),
								md: 0,
							},
						},
					}}
				>
					{/* 프로그램 정보 */}
					<UIFlex
						flexDirection="row"
						alignItems="center"
						sx={{
							width: '38.9%',
							minWidth: pxToRem(130),
						}}
					>
						<Box
							display={{ xs: 'none', lg: 'block' }}
							width={pxToRem(42)}
							height={pxToRem(42)}
							overflow="hidden"
							borderRadius="9999px"
							mr={pxToRem(12)}
							sx={{
								img: {
									width: '100%',
								},
							}}
						>
							<UIImage ratio="1" src={aodInfo?.programIconImgUrl} alt="" />
						</Box>
						<Box>
							<UIText
								fontSize={pxToRem(15)}
								lineHeight={pxToRem(22)}
								fontWeight={600}
								color={color.colBlack}
								lineClamp={1}
							>
								{currentAod?.insertDate && (
									<>
										<UIText component="span">
											{dayjs(currentAod?.insertDate).format(
												DATE_FORMAT.DATE_FULL,
											)}
										</UIText>
										<UIText
											component="span"
											sx={(theme) => ({
												[theme.breakpoints.down('md')]: {
													display: 'none',
												},
											})}
										>
											{dayjs(currentAod?.insertDate).format(' ddd요일')}
										</UIText>
									</>
								)}
							</UIText>
							<UIText
								fontSize={pxToRem(14)}
								lineHeight={pxToRem(20)}
								fontWeight={500}
								color={color.colGray5}
								lineClamp={1}
							>
								{aodInfo?.programName ?? 'ㅤ'}
							</UIText>
						</Box>
					</UIFlex>
					<AodControls
						info={aodInfo as ProgramAodResponseVo}
						playAod={currentAod}
						audio={audio}
						currentTime={currentTime}
						duration={duration}
						onPrev={handlePrev}
						onNext={handleNext}
					/>
					<AodViewMoreModal />
					<Box>
						<button type="button" className="button-close" onClick={onClose}>
							<Icon32Close />
							<UIText readonly>플레이어 닫기</UIText>
						</button>
					</Box>
				</UIFlex>
			</Box>
		)
	)
}

export default AodBarRoot
